// Site information constants
export const SITE_ADDRESS = {
  streetAddress: 'Weinerpark 16',
  addressLocality: 'Ochtrup',
  addressRegion: 'NRW',
  postalCode: '48607',
  addressCountry: 'DE',
}

export const SITE_CONTACT = {
  telephone: '+4908007000220',
  contactType: 'customer service',
  email: 'international@ggmgastro.com',
}

export const SITE_COORDINATES = {
  latitude: '52.195571',
  longitude: '7.153961',
}

export const SITE_NAME = 'GGM Gastro International GmbH'

export const SITE_SOCIAL_MEDIA = [
  'https://www.facebook.com/ggmgastro',
  'https://www.youtube.com/user/wwwGGMGASTROcom',
  'https://www.instagram.com/ggmgastro/',
  'https://www.tiktok.com/@ggmgastro',
  'https://x.com/ggmgastro',
  'https://www.linkedin.com/company/ggmgastro',
]

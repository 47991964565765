import { memo } from 'react'
import {
  SITE_NAME,
  SITE_COORDINATES,
  SITE_SOCIAL_MEDIA,
} from '@constants/siteInfo'
import {
  LocalBusinessJsonLd,
  LogoJsonLd,
  OrganizationJsonLd,
  SiteLinksSearchBoxJsonLd,
} from 'next-seo'

// Memoized SEO component to prevent unnecessary re-renders
const SiteJsonLd = memo(({ domain, locales, address, contact }) => (
  <>
    <LocalBusinessJsonLd
      type="Store"
      id={`${domain}/${locales}`}
      name={SITE_NAME}
      url={`${domain}/${locales}`}
      telephone={contact.telephone}
      address={address}
      geo={SITE_COORDINATES}
      images={[
        `${domain}/local_business/01.jpg`,
        `${domain}/local_business/02.jpg`,
        `${domain}/local_business/03.jpg`,
        `${domain}/local_business/04.jpg`,
        `${domain}/local_business/05.jpg`,
      ]}
      sameAs={SITE_SOCIAL_MEDIA}
    />
    <LogoJsonLd
      logo={`${domain}/print/ggmgastroLogo.png`}
      url={`${domain}/${locales}`}
    />
    <OrganizationJsonLd
      type="Corporation"
      id={`${domain}/${locales}`}
      logo={`${domain}/print/ggmgastroLogo.png`}
      legalName={SITE_NAME}
      name={SITE_NAME}
      address={address}
      contactPoint={[contact]}
      url={`${domain}/${locales}`}
    />
    <SiteLinksSearchBoxJsonLd
      url={`${domain}`}
      potentialActions={[
        {
          target: `${domain}/${locales}/search?q`,
          queryInput: 'search_term_string',
        },
      ]}
    />
  </>
))

SiteJsonLd.displayName = 'SiteJsonLd'

export default SiteJsonLd
